import {
  Button,
  CircularProgress,
} from '@mui/material'
import { type Theme } from '@mui/material/styles/createTheme'
import {
  Box,
  type SxProps,
} from '@mui/system'
import { type SerializedError } from '@reduxjs/toolkit'
import {
  type FC,
  useEffect,
} from 'react'

import { baseTheme } from 'app/config/theme/baseTheme'
import {
  checkOptions,
  checkPermission,
  clearDrawingsBlob,
  downloadDrawings,
  type RootState,
  selectUnitsDrawings,
  selectUnitsFilters,
  useAppDispatch,
  useAppSelector, type UnitsFilterState,
} from 'app/store'

import { OptionsUnitDocumentation } from 'shared/api/options'
import { useFeatureSlicedDebug } from 'shared/lib/useFeatureSlicedDebug'
import { PermissionsDocumentationOutlineDownload } from 'shared/model/contracts/permissions'
import { DownloadFileIcon } from 'shared/ui/Icons/DownloadFileIcon'

import useShowUnitsErrorsWithLog from "../../../shared/lib/useShowUnitsErrorsWithLog";

interface DownloadDrawingsProps {
  sx?: SxProps<Theme>
}

export const DownloadDrawings: FC<DownloadDrawingsProps> = ({
  sx,
}) => {
  const setError = useShowUnitsErrorsWithLog()

  const {
    rootAttributes,
  } = useFeatureSlicedDebug('feature/DownloadDrawings')

  const filtersState: UnitsFilterState = useAppSelector(selectUnitsFilters)
  const {
    unitFilter,
    prevFilters,
    unitFilters,
    nextFilters,
  } = filtersState
  const filters = [...prevFilters, ...unitFilters, ...nextFilters]
  const drawingsState = useAppSelector(selectUnitsDrawings)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (drawingsState?.blob) {
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.href = drawingsState?.blob
      a.download = 'Outline drawings.zip'
      a.click()
      document.body.removeChild(a)
      dispatch(clearDrawingsBlob())
    }
  }, [drawingsState?.blob])

  const handleDownloadDrawings = () => {
    dispatch(downloadDrawings({
      filter: unitFilter ?? '',
      filters,
    }))
      .unwrap()
      .catch((e: SerializedError) => {
        setError({
          message: e.message || 'An error occurred while loading drawings',
          type: 'error',
        })
      })
  }

  const canDownloadOutline = useAppSelector(
    (root: RootState) =>
      checkPermission(root, PermissionsDocumentationOutlineDownload) &&
      checkOptions(root, OptionsUnitDocumentation),
  )

  return (
    <Box
      {...rootAttributes}
      sx={{
        [baseTheme.breakpoints.down(baseTheme.breakpoints.values.md)]: { width: '100%' },
        [baseTheme.breakpoints.up(baseTheme.breakpoints.values.md)]: { width: 'inherit' },
      }}
    >
      {canDownloadOutline && (
        <Button
          fullWidth
          data-testid="download-drawings"
          variant="outlined"
          disabled={drawingsState.status === 'loading'}
          startIcon={drawingsState.status === 'loading'
            ? <CircularProgress size={16}/>
            : <DownloadFileIcon/>}
          onClick={handleDownloadDrawings}
          sx={{
            height: '40px',
            ...sx,
          }}
        >
          Download found OD
        </Button>
      )}
    </Box>
  )
}
