import { InteractionType } from '@azure/msal-browser'
import {
  MsalAuthenticationTemplate,
  MsalProvider,
} from '@azure/msal-react'
import { ThemeProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  createRoutesFromElements,
  matchRoutes,
  Route,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import { ecTheme } from 'app/config/theme/ecTheme'

import './index.css'
import LoadingPageView from 'pages/LoadingPageView'

import RolePage from 'widgets/administration/roles/RolePage'
import { RolesListPage } from 'widgets/administration/roles/RolesListPage'
import SettingsPage from 'widgets/administration/settings/SettingsPage'
import UsersListPage from 'widgets/administration/users/UsersListPage'
import NotFoundPage from 'widgets/errors/NotFoundPage'

import authService from 'shared/api/AuthService'
import { ErrorBoundary } from 'shared/ui/components/ErrorBoundary'

import {
  persistor,
  store,
} from './app/store'
import BrokersPage from './pages/BrokersPage'
import SyncPage from './pages/SyncPage'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV6(createBrowserRouter);

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route path="/" lazy={async () => await import("../src/pages/LayoutPage")}>
      <Route
        index
        lazy={async () => await import("../src/pages/LandingPage")}
      />
      <Route path="logistic">
        <Route
          path="catalog"
          lazy={async () => await import("../src/pages/LogisticCatalog")}
        />
        <Route
          lazy={async () =>
            await import("../src/pages/TechnicalDescriptionsPage")
          }
          path="technical-description"
        />
      </Route>
      <Route
        path="units"
        lazy={async () => await import("../src/pages/UnitsSearchPage")}
      />
      <Route path="administration">
        <Route
          path="settings"
          element={
            <ThemeProvider theme={ecTheme}>
              <SettingsPage />
            </ThemeProvider>
          }
        />
        <Route
          path="sync"
          element={
            <ThemeProvider theme={ecTheme}>
              <SyncPage />
            </ThemeProvider>
          }
        />
        <Route
          path="brokers"
          element={
            <ThemeProvider theme={ecTheme}>
              <BrokersPage />
            </ThemeProvider>
          }
        />
        <Route
          path="users"
          element={
            <ThemeProvider theme={ecTheme}>
              <UsersListPage />
            </ThemeProvider>
          }
        />
        <Route
          path="roles"
          element={
            <ThemeProvider theme={ecTheme}>
              <RolesListPage />
            </ThemeProvider>
          }
        />
        <Route
          path="roles/:roleId"
          element={
            <ThemeProvider theme={ecTheme}>
              <RolePage />
            </ThemeProvider>
          }
        />
      </Route>

      <Route
        path="*"
        element={
          <ThemeProvider theme={ecTheme}>
            <NotFoundPage />
          </ThemeProvider>
        }
      />
    </Route>
  )
);

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <MsalProvider instance={await authService.getInstance()}>
      <Provider store={store}>
        <PersistGate
          loading={<LoadingPageView/>}
          persistor={persistor}
        >
          <ThemeProvider theme={ecTheme}>
            <SnackbarProvider maxSnack={5}>
              <ErrorBoundary>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  <RouterProvider router={router}/>
                </MsalAuthenticationTemplate>
              </ErrorBoundary>
            </SnackbarProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
)
