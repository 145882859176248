import {useSnackbar, VariantType} from "notistack";
import {useEffect, useState } from "react"

import {addError, selectUnitsErrors, useAppDispatch, useAppSelector} from "app/store"

import {clearErrorsQueue, ErrorMessage} from "../../app/store/units/unitsErrorsSlice";

const useShowUnitsErrorsWithLog = () => {

  const {
    enqueueSnackbar,
  } = useSnackbar()

  const errors = useAppSelector(selectUnitsErrors)
  const [error,setError] = useState<ErrorMessage | null>(null)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (error) {
      dispatch(addError({
        message: error.message,
        type: 'error',
      }))
    }
  }, [error])

  useEffect(() => {
    if (errors.errorsQueue.length > 0) {

      function enqueue (error: ErrorMessage) {
        enqueueSnackbar(error.message, {
          variant: error.type as VariantType ?? 'warning',
          preventDuplicate:true,

        })
      }

      const errorQueue = errors?.errorsQueue

      errorQueue?.forEach((currentError, index) => {
        setTimeout(() => {
          enqueue(currentError)
        },  index * 800)
      })

      if (errors?.errorsQueue?.length) {
        dispatch(clearErrorsQueue())
      }
      setError(null)
    }

  },[errors.errorsQueue.length])

  return setError
}

export default useShowUnitsErrorsWithLog
