import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import createUnitDialog from './createUnitDialogSlice'
import customDownloadDialog from './customDownloadDialogSlice'
import initialsDialog from './initialsDialogSlice'
import printDialog from './printDialogSlice'
import printOutlineDialog from './printOutlineDialogSlice'
import drawings from './unitsDrawingsSlice'
import errors from './unitsErrorsSlice'
import filters from './unitsFiltersSlice'
import cards from './unitsInspectionCardsSlice'
import items from './unitsListSlice'
import report from './unitsReportSlice'
import options from './unitsSearchOptionsSlice'

const persistListConfig = {
  key: 'list',
  storage,
  blacklist: ['errors','report'],
}

const persistItemsConfig = {
  key: 'items',
  storage,
  blacklist: ['error', 'missedArticles', 'internalErrors'],
}

const list = combineReducers({
  items: persistReducer(persistItemsConfig, items),
  filters,
  report,
  drawings,
  options,
  errors,
  printDialog,
  customDownloadDialog,
  createUnitDialog,
  printOutlineDialog,
  initialsDialog,
  cards,
})

export const units = combineReducers({ list: persistReducer(persistListConfig, list) })
